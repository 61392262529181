import AltaviaViewer from "./altavia-viewer";
import { Container, Col, Row } from "reactstrap";
import ViewerSidebar from "./viewer-sidebar";
import { useState } from "react";

const ViewerWrapper = () => {
  const [pdfTronInstance, setPdfTronInstance] = useState(null); // the lifted state

  const hasInstance = (instance) => {
    console.log("has instance", instance);
    setPdfTronInstance(instance);
  };

  return (
    <Container fluid={true} className="m-0 p-0 vw-100">
      <Row className="vh-100 vw-100 m-0">
        <Col xs="2" className="m-0 p-0">
          <ViewerSidebar instance={pdfTronInstance}
    />
        </Col>
        <Col xs="10" className="m-0 p-0">
          <AltaviaViewer
            setPdfTronInstance={hasInstance}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default ViewerWrapper;
