import styled from 'styled-components';

export const AexViewerLogo = styled.img`
max-height:1.5em;
`;

export const SideBarNav = styled.div`
height: 36px;
max-width:100%;
background-color:#343434;
border-bottom: 1px solid #484848;
`
