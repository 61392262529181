import { useState, useEffect } from "react";
import CollapsiblePanelTitle from "../../../ui-common/collapsible-panel-title";
import { Collapse, Col } from "reactstrap";
import { faPalette, faCheck } from "@fortawesome/free-solid-svg-icons";
import { ColorListItem, ColorSwatch } from "./style";
import { isColorLightOrDark } from "../../../common/color.utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WebViewerInstance } from "@pdftron/webviewer";
const ColorListItemWrapper = (props) => {
  const { colorData, onClick } = props;

  const toggleColor = () => {
    onClick(!colorData.enabled);
  };

  const rgb = `rgb(${colorData.rgb[0]},${colorData.rgb[1]},${colorData.rgb[2]})`;
  return (
    <ColorListItem
      className="d-flex flex-row align-items-center"
      onClick={toggleColor}
    >
      <ColorSwatch
        style={{ backgroundColor: rgb }}
        className={isColorLightOrDark(colorData.rgb)}
      >
        {colorData.enabled && <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>}
      </ColorSwatch>{" "}
      {colorData.name}
    </ColorListItem>
  );
};

const SidebarColorLayerPicker = (props) => {
  const instance: WebViewerInstance = props.instance;
  const [colors, setColors] = useState([]);
  const [randomState, setRandomState] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    instance?.docViewer.on("documentLoaded", () => {
      const pdfDoc = instance.docViewer.getDocument();

      pdfDoc.enableColorSeparations(true);
      
      pdfDoc?.on("colorSeparationAdded", () => {
        const allColors = pdfDoc?.getColorSeparations();
        setColors(allColors);
        setRandomState(allColors.length+Math.random()); //Not sure why but this is required to re-render after the state change
      });
    });
  });

  const handleCollapseToggle = () => {
    //Temporary. TODO: A better UI would be to have collapsible panels but for a demo it looks empty
    setIsOpen(!isOpen)
  };

  const toggleColor = (color, visible) => {
    const pdfDoc = instance.docViewer.getDocument();
    pdfDoc?.enableSeparation(color.name, visible);
    const allColors = pdfDoc?.getColorSeparations();
    setColors(allColors);
    setRandomState(allColors.length+Math.random()); //Not sure why but this is required to re-render after the state change

    instance.docViewer.refreshAll();

    //@ts-ignore
    instance.docViewer.updateView(); //From PDFTron's doc
  };

  return (
    <Col xs="12" className="p-0 m-0">
      <CollapsiblePanelTitle
        title="Séparation"
        shouldShowLoader={randomState === 0}
        onClick={handleCollapseToggle}
        icon={faPalette}
        isOpen={isOpen}
      />
      <Collapse isOpen={isOpen} className="pt-2">
        {colors?.map((color) => (
          <ColorListItemWrapper
            key={color.name}
            colorData={color}
            onClick={(visible) => toggleColor(color, visible)}
          />
        ))}
      </Collapse>
    </Col>
  );
};

export default SidebarColorLayerPicker;
