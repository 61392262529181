import InlineLoader from "../inline-loader";
import {
  ClickableTitle,
  DisclosureTriangleContainer,
  PrefixContainer,
  TitleLabel,
} from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

const CollapsiblePanelTitle = ({
  title,
  shouldShowLoader,
  onClick,
  icon,
  isOpen,
}) => {
  return (
    <ClickableTitle
      onClick={onClick}
      className="p-1 px-3 d-flex flex-row align-items-center"
    >
      <PrefixContainer>
        {shouldShowLoader ? (
          <InlineLoader />
        ) : (
          <FontAwesomeIcon icon={icon} className="menu-icon" />
        )}
        {!shouldShowLoader && 
        <DisclosureTriangleContainer>
          <FontAwesomeIcon
            icon={isOpen ? faChevronDown : faChevronRight}
            className="menu-icon"
          />
        </DisclosureTriangleContainer>
         }
      </PrefixContainer>

      <TitleLabel>{title}</TitleLabel>
    </ClickableTitle>
  );
};

export default CollapsiblePanelTitle;
