import styled from 'styled-components';

export const ColorListItem = styled.div`
width:100%;
height:25px;
cursor:pointer;
font-size:12px;
`

export const ColorSwatch = styled.div`
 width:20px;
 height:20px;
 display:inline-block;
 border:1px solid #eee;
 font-size:14px;
 margin-right:10px;
 margin-left:10px;
 text-align:center;
 &.light{
     color:black;
 }
 &.dark {
     color:white;
 }
`