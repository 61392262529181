import { useEffect, useRef } from "react";
import WebViewer from "@pdftron/webviewer";
// import CoreControls from "@pdftron/webviewer";
import License from "./license";

const AltaviaViewer = (props) => {
  const viewer = useRef(null);

  const qs = new URLSearchParams(window.location.search);

  const initialDoc = qs?.get("atob") ? atob(qs?.get("atob")) : qs?.get("file");
  const initialLang = qs?.get("lc") || "fr";
  useEffect(() => {
    WebViewer(
      {
        path: "/webviewer/lib/",
        initialDoc: initialDoc || "/sample-files/sleeve-yab-fraise.pdf",
        css: "/viewer.style.css",
        enableMeasurement: true,

        licenseKey: License,
        //@ts-ignore
        // pdftronServer:'http://10.9.8.220:8090' //PDFTron doc...
      },
      viewer.current
    ).then((instance) => {
      props.setPdfTronInstance(instance);
      const { docViewer } = instance;
      const distanceMeasurementTool = docViewer.getTool(
        "AnnotationCreateDistanceMeasurement"
      );
      distanceMeasurementTool.setStyles(() => ({
        Scale: [
          [1, "mm"],
          [1, "mm"],
        ],
      }));
      instance.setLanguage(initialLang);
      instance.enableFeatures([instance.Feature.Measurement]);
      instance.setLayoutMode(instance.LayoutMode.FacingContinuous);
      instance.enableElements(["leftPanel"]);

      // instance.disableElements(["toolbarGroup-Shapes"]);
      // instance.disableElements(["toolbarGroup-Edit"]);
      // instance.disableElements(["toolbarGroup-Insert"]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="MyComponent">
      <div
        className="webviewer"
        ref={viewer}
        style={{ height: "100vh", width: "100%" }}
      ></div>
    </div>
  );
};

export default AltaviaViewer;
