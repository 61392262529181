import { useState, useEffect } from "react";
import CollapsiblePanelTitle from "../../../ui-common/collapsible-panel-title";
import { Collapse, Col } from "reactstrap";
import { faBarcode } from "@fortawesome/free-solid-svg-icons";
import { CoreControls, WebViewerInstance } from "@pdftron/webviewer";
import { recognizeBarcodeFromCanvas } from "./barcode.utils";

const SidebarBarcodeExtractor = (props) => {
  const instance: WebViewerInstance = props.instance;
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let pdfDoc: CoreControls.Document;
  useEffect(() => {
    instance?.docViewer.on("documentLoaded", () => {
      pdfDoc = instance.docViewer.getDocument();
    });
  });

  const handleCollapseToggle = () => {
    setIsOpen(!isOpen);
  };

  const recognizeBarcodeOnPage = async () => {
    const iframeWindow = instance?.iframeWindow;
    const iframeDocument = iframeWindow.document;
    const pageIndex = instance?.docViewer.getCurrentPage();
    

    const pageContainer = iframeDocument.getElementById(
      "pageContainer" + pageIndex
    );
    const pageCanvas: HTMLCanvasElement = pageContainer.querySelector(".canvas" + pageIndex);
   
    console.log(recognizeBarcodeFromCanvas(pageCanvas));
  };

  return (
    <Col xs="12" className="p-0 m-0">
      <CollapsiblePanelTitle
        title="Codes-barres"
        shouldShowLoader={isLoading}
        onClick={handleCollapseToggle}
        icon={faBarcode}
        isOpen={isOpen}
      />
      <Collapse isOpen={isOpen} className="pt-2">
        <div className="text-center">
          <button
            className="btn btn-sm btn-secondary mt-2 mx-auto"
            onClick={recognizeBarcodeOnPage}
          >
            Reconnaître sur cette page
          </button>
        </div>
      </Collapse>
    </Col>
  );
};

export default SidebarBarcodeExtractor;
