import styled from 'styled-components';

export const PrefixContainer = styled.div`
display:inline-block;
margin-right:1em;
`;

export const TitleLabel = styled.div`
display:inline-block;
color:#868686;
font-size:14px;
font-weight:600;`

export const ClickableTitle = styled.div`
cursor:pointer;
border-top:1px solid #484848;
`

export const DisclosureTriangleContainer = styled.div`
display:inline-block;
position:absolute;
right:10px;
`