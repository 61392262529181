import React from 'react';
import './styles/App.scss';

import ViewerWrapper from './viewer-wrapper';

function App() {
  return (
      <ViewerWrapper/>
    
  );
}

export default App;
