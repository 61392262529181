const encodedLicense = process.env.REACT_APP_PDFTRON_LICENSE || localStorage['_PDFTRON_LICENSE'] || undefined;

const defs = (str, key, n = 126) => {
    if (!(typeof (key) === 'number' && key % 1 === 0)
        || !(typeof (key) === 'number' && key % 1 === 0)) {
        return str.toString();
    }

    return obfs(str.toString(), n - key);
};

const obfs = (str, key, n = 126) => {
    if (!(typeof (key) === 'number' && key % 1 === 0)
        || !(typeof (key) === 'number' && key % 1 === 0)) {
        return str.toString();
    }

    var chars = str.toString().split('');

    for (var i = 0; i < chars.length; i++) {
        var c = chars[i].charCodeAt(0);

        if (c <= n) {
            chars[i] = String.fromCharCode((chars[i].charCodeAt(0) + key) % n);
        }
    }

    return chars.join('');
};
const License = encodedLicense ? defs(atob(encodedLicense), 122) : undefined;
export default License;