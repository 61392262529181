import Loader from "react-loader-spinner";

const InlineLoader = () => {
    return (
        <Loader
        type = "ThreeDots"
        color = "#FFC42C"
        height = { 20}
        width = { 20}
            />
      )
}

export default InlineLoader;