import { AexViewerLogo, SideBarNav } from "./style";
import { Row, Col } from "reactstrap";
import SidebarColorLayerPicker from "./sidebar-color-layer-picker";
import SidebarBarcodeExtractor from "./sidebar-barcode-extractor";
import { WebViewerInstance } from "@pdftron/webviewer";

function ViewerSidebar(props) {
  const instance: WebViewerInstance = props.instance;

  return (
    <>
      <Row className="m-0 p-0 w-100">
        <Col
          xs="12"
          className="m-0 p-0"
          style={{ borderRight: "1px solid #484848" }}
        >
          <SideBarNav className="pt-1 pl-3 w-100">
            <AexViewerLogo src="/execute-light-long.svg" />
          </SideBarNav>
        </Col>
        {/* <Col xs="12">
          {pdfDoc?.filename}
          </Col> */}
        <Col xs="12" className="mt-2"><h2>Outils</h2></Col>
        {instance && <SidebarColorLayerPicker instance={instance} />}
        {instance && <SidebarBarcodeExtractor instance={instance} />}

      </Row>
    </>
  );
}

export default ViewerSidebar;
