import javascriptBarcodeReader from 'javascript-barcode-reader'

const _debugPrintImage = (image: HTMLImageElement) => {
    console.log(image.src);
    console.log('%c ', 'font-size:' + image.width + 'px; background:url(' + image.src + ') no-repeat;');
}

declare var BarcodeDetector: any;

export const recognizeBarcodeFromCanvas = (canvas: HTMLCanvasElement) => {

    const img = new Image();
    img.height = canvas.height;
    img.src = canvas.toDataURL();
    img.width = canvas.width;
    document.body.appendChild(img);

    // if (BarcodeDetector) {
    //     const barcodeDetector = new BarcodeDetector({ formats: ['ean_8'] })
    //     barcodeDetector.detect(img)
    //     .then(barcodes => {
    //         console.log('barcodes detected', barcodes)
    //         barcodes.forEach(barcode => console.log(barcode.rawData));
    //       })
    //       .catch(err => {
    //         console.log(err);
    //       })
    // }
    console.log('jsb', javascriptBarcodeReader)
    javascriptBarcodeReader({
        /* Image file Path || {data: Uint8ClampedArray, width, height} || HTML5 Canvas ImageData */
        image: canvas,
        barcode: 'ean-8',
        
      })
        .then(code => {
          console.log(code)
        })
        .catch(err => {
          console.log(err)
        })

    // // Quagga.decodeSingle({
    // //     decoder: {
    // //         readers: ["upc_e_reader", "upc_reader","ean_reader","ean_8_reader"] // List of active readers
    // //     },
    // //     locate: true, // try to locate the barcode in the image
    // //     src: canvas.toDataURL() // or 'data:image/jpg;base64,' + data
    // // }, function(result){
    // //     if(result?.codeResult) {
    // //         console.log("result", result.codeResult.code);
    // //     } else {
    // //         console.log("not detected");
    // //     }
    // // });

    // javascriptBarcodeReader({
    //     /* Image file Path || {data: Uint8ClampedArray, width, height} || HTML5 Canvas ImageData */
    //     image: canvas}) .then(code => {
    //         console.log(code)
    //       }).catch(err => {
    //         console.log(err)
    //       })


}